.BackgroundModal {
  background-color: #0f0f0fba !important;


}

.Btn {
  color: #FFFFFF !important;
  background-color: #4FE894 !important;
}

.Btn:hover { 
  color: #000000 !important; 
}

.modalStyle {
  padding: 30px !important;
}

.CloseModal {
  margin: 0 auto;
}


.Icon {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.IconActive {
  transform: scale(0.6);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.TestSearch {
  text-align: center;
}

.SearchBar {
  width: 60%;
  margin-bottom: 10px;
}

.FilterBtn {
  margin-left: -15px !important;
}
.ResetBtn {
  margin-left: 3px !important;
}

.AnimateIn {
  animation: openModal 0.4s ease-out forwards;
}
@keyframes openModal{
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 1;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



  
