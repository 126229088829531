.Inline{
    display:flex; 
    flex-direction:row;
}

.InlineSeparate {
    margin-left: 2px;
}

.Selection {
    border-color: #4FE894 !important;
}
  
.Selection:not(:hover)::after {
border-color: #4FE894 !important;
}

.Label {
    font-size: small;
}

.LabelLeft {
    margin-right: 4.2em;
}