h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: .8em;
  margin: 1em 0;
}

.product-edit-icon {
  opacity: .7;
  position: absolute;
  right: 2.3rem;
  top: .4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar, .navbar-end, .navbar-menu, .navbar-start {
  align-items: stretch !important;
  display: flex !important;
  background-color: #4FE894 !important;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: flex !important;
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
}


.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.buttons {
  margin-left: 1em;
}

.BackgroundModal {
  background-color: #272a2847;
}

