.ListSection {
  background-color: #d8d5d59f;
}

.NotificationStyle {
  margin-top: 20px;
  text-align: center;
}

.NotAvailable {
  color: gray;
}

.BackgroundModal {
  background-color: #0f0f0fba !important;
}

.Btn {
  color: #FFFFFF ;
  background-color: #4FE894 !important;
  margin-bottom: 4px;
  margin-top: 20px;
}

.Btn:hover { 
  color: #000000 !important; 
}

.CloseModal {
  margin: 0 auto;
}

.AnimateIn {
  animation: openModal 0.4s ease-out forwards;
}
@keyframes openModal{
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 1;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

