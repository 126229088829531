.Section {
    margin-top: 4px;
    border-radius: 5px
}

.Section:hover {
    color: #000000; 
}

.SectionSuccess {
    margin-top: 4px;
    background-color: #4FE894 !important;
    color:#FFFFFF !important;
}

.Btn {
    color: #FFFFFF !important;
    background-color: #4FE894 !important;
}

.Btn:hover { 
    color: #000000 !important; 
}

.ChartHeader {
    text-align: center;
    margin-bottom: initial;
}

.ChartDiv {
    margin-bottom:1em;
}

.ReportSection {
    margin-top:4px;
}