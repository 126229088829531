.Headline {
  text-align: center;
  margin-bottom: 40px;
}

.Selection {
  border-color: #4FE894 !important;
}

.Selection:not(:hover)::after {
  border-color: #4FE894 !important;
}

.Section {
  margin-top: 4px;
  border-radius: 5px;
  border-color: #4FE894 !important;
  text-align: center;
}

.Section:hover {
  color: #000000;
  text-align: center;
}

.SectionSuccess {
  margin-top: 4px;
  background-color: #4FE894 !important;
  color: #FFFFFF ;
  text-align: center;
}

.Btn {
  color: #FFFFFF ;
  background-color: #4FE894 !important;
  margin-bottom: 4px;
  margin-top: 20px;
}

.Btn:hover { 
  color: #000000 !important; 
}

.Generate {
  margin: auto;
  margin-top: 30px;
}

form ul {
  list-style-type: none !important;
}

.Ul {
  margin-left: 0 !important;
  margin-top: 2px !important;
}
.List {
  margin: -5px 0;
}

input[type='checkbox'] { 
  margin-right: 5px;
}

.InputGenerate {
  border-color: #4FE894 !important;
  margin-top: 3px;
}

.Scenario {
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
  padding-top: 10px;
}

.Loader {
  color: #4FE894 !important;
  text-align: center;
}



