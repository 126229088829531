.PaginationStyle {
    margin-bottom: 10px;
}

.PageBtn {
    background-color: #4FE894 !important;
    border-radius: 8px !important;
}

.Span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

.Btn {
    color: #FFFFFF !important;
    background-color: #4FE894 !important;
    margin-left: -15px;
  }
  


